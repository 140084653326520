import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://admin.clovefinedine.com/api/subscribe', { email });
      toast.success(response.data.message);
      setEmail('');
    } catch (err) {
      if (err.response && err.response.data.errors) {
        toast.error(err.response.data.errors.email[0]);
      } else {
        toast.error('An error occurred. Please try again.');
      }
    }
  };

  return (
    <>
      <footer
        className="main-footer bgc-black rel z-1"
        style={{ backgroundImage: "url(assets/images/background/footer-bg.png)" }}
      >
        <div className="footer-top py-130 rpy-100">
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-xl-7 col-lg-9"
                data-aos="fade-up"
                data-aos-duration={1500}
                data-aos-offset={50}
              >
                <div className="section-title text-white text-center mb-35">
                  <span className="sub-title mb-10">join our newsletter</span>
                  <h2>subscribe our newsletter<br></br> to get more updates</h2>
                </div>
                <form className="newsletter-form" onaSubmit={handleSubmit}>
                  <label htmlFor="news-email">
                    <i className="fas fa-envelope" />
                  </label>
                  <input
                    id="news-email"
                    type="email"
                    placeholder="Email Address"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button className="theme-btn" type="submit">
                    Subscribe <i className="far fa-arrow-alt-right" />
                  </button>
                </form>
              </div>
            </div>
          </div>
          
        </div>
        <div className="widget-area pb-70">
      <div className="container">
        <div className="row justify-content-between">
          <div
            className="col-xl-3 col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-offset={0}
          >
            <div className="footer-widget footer-text">
              <div className="footer-logo mb-25">
                <Link to="/">
                  {/* <img src="assets/images/logos/logo.png" alt="Logo" /> */}
                  <h2 className='text-white'>Clove</h2>
                </Link>
              </div>
              <p>
              Welcome to Clove, At Clove, we blend exquisite flavors with exceptional service to create an unforgeIable experience.   </p>
              <div className="social-style-one mt-15">
                <a>
                  <i className="fab fa-facebook-f" />
                </a>
                <a>
                  <i className="fab fa-twitter" />
                </a>
                <a>
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="https://www.instagram.com/clove_pollachi?igsh=Z3ExbTlobm1zNG01" target='_BLANK'>
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-5 col-sm-6"
            data-aos="fade-up"
            data-aos-delay={50}
            data-aos-duration={1500}
            data-aos-offset={0}
          >
            <div className="footer-widget footer-links">
              <div className="footer-title">
                <h5>Quick Links</h5>
              </div>
              <ul className="two-column">
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to='/reservation'>Reservation</Link>
                </li>
                <li>
                  <Link to='/about-us'>About Us</Link>
                </li>
                <li>
                  <Link to='/menu'>Menu</Link>
                </li>
                <li>
                  <Link to='/gallery'>Gallery</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="row justify-content-between">
              <div
                className="col-xl-6 col-lg-5 col-sm-6"
                data-aos="fade-up"
                data-aos-delay={100}
                data-aos-duration={1500}
                data-aos-offset={0}
              >
                <div className="footer-widget footer-contact">
                  <div className="footer-title">
                    <h5>contact us</h5>
                  </div>
                  <ul>
                    <li>
                    Clove Restaurant, No.27 Nehru Street, A Block B Colony, Opp.Water tank, Mahalingapuram,<br></br> Pollachi - 642002
                    </li>
                    <li>
                      <a href="mailto:sales@clovefinedine.com">
                        <u>sales@clovefinedine.com
                        </u>
                      </a>
                    </li>
                    <li>
                      <a href="callto:+919043555270">+91 90435 55270</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-xl-6 col-lg-5 col-sm-6"
                data-aos="fade-up"
                data-aos-delay={150}
                data-aos-duration={1500}
                data-aos-offset={0}
              >
                <div className="footer-widget opening-hour">
                  <div className="footer-title">
                    <h5>opening hour</h5>
                  </div>
                  <ul>
                    <li>Open all 7 Days</li>
                    <li>
                      Lunch: <span>12pm – 4pm</span>
                    </li>
                    <li>
                     Dinner:  <span>7pm – 11pm</span>
                    </li>
                  </ul>
                  <div className="any-question mt-20">
                    <h5>Have any questions?</h5>
                    <Link to="/contact" className="theme-btn style-two">
                      Contact Us <i className="far fa-arrow-alt-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom pt-30 pb-15">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="copyright-text text-center text-lg-start">
              <p>
                Copyright 2024 <a>Clove</a>. All Rights
                Reserved{" "}
              </p>
            </div>
          </div>
         
        </div>
        {/* Scroll Top Button */}
        <button className="scroll-top scroll-to-target" data-target="html">
          <i className="fas fa-arrow-alt-up" />
        </button>
      </div>
    </div>
    <div className="footer-shapes">
      <div className="shape one">
        {/* <img src="assets/images/shapes/hero-shape5.png" alt="Shape" /> */}
      </div>
      <div className="shape two">
        {/* <img src="assets/images/shapes/tomato.png" alt="Shape" /> */}
      </div>
      <div className="shape three">
        {/* <img src="assets/images/shapes/pizza-two.png" alt="Shape" /> */}
      </div>
    </div>
      </footer>
      <ToastContainer />
    </>
  );
};

export default Footer;
