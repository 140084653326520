import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import React, { useEffect , useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import slider from './ImageSlider.css';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import AutoDate from './AutoDate';


function Home() {


  const [blogs, setBlogs] = useState([]);

  const loadScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    script.onerror = () => console.error(`Failed to load script: ${src}`);
    document.head.appendChild(script);
  };

  useEffect(() => {


 


    // List of scripts to load
    const scripts = [
     
      '/assets/js/scriptaaa.js'
    ];

    // Load each script
    scripts.forEach((src, index) => {
      loadScript(src, () => {
        console.log(`Script loaded: ${src}`);
        // Optionally handle initialization here
      });
    });

// Fetch data from API using axios
const fetchData = async () => {
  try {
    const response = await axios.get('https://admin.clovefinedine.com/api/blogs');
    setBlogs(response.data);
  } catch (error) {
   
  }
};

fetchData();
window.scrollTo(0, 0);

    // Cleanup function (if needed)
    return () => {
      // Optionally clean up if necessary
    };
  }, []);

   


  const [modalOpen, setModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const options = {
    loop: true,
    margin: 30,
    autoplay: true,
    autoplayTimeout: 3000,
    dots: false,
    nav: false,
    responsive: {
      0: { items: 1, nav: false },
      480: { items: 2 },
      768: { items: 4, nav: false },
    },
  };

  
  const optionsTest = {
    loop: false,
    margin: 30,
    autoplay: true,
    autoplayTimeout: 3000,
    dots: false,
    nav: false,
    responsive: {
      0: { items: 1, nav: false },
      480: { items: 2 },
      768: { items: 3, nav: false },
    },
  };


  // const images = [
  //   "assets/images/gallerys/Image 1.jpg",
  //   "assets/images/gallerys/Image 2.jpg",
  //   "assets/images/gallerys/Image 3.jpg",
  //   "assets/images/gallerys/Image 4.jpg",
  //   "assets/images/gallerys/Image 5.jpg",
  //   "assets/images/gallerys/Image 6.jpg",
  //   "assets/images/gallerys/Image 7.jpg",
  //   "assets/images/gallerys/Image 8.jpg",
  //   "assets/images/gallerys/Image 9.jpg",
  //   "assets/images/gallerys/Image 10.jpg",
  //   "assets/images/gallerys/Image 11.jpg",
  //   "assets/images/gallerys/Image 12.jpg",
    
  // ];

  const images = [
    "assets/images/Food Photography/1.jpg",
    "assets/images/Food Photography/2.jpg",
    "assets/images/Food Photography/3.jpg",
    "assets/images/Food Photography/4.jpg",
    "assets/images/Food Photography/5.jpg",
    "assets/images/Food Photography/6.jpg",
    "assets/images/Food Photography/7.jpg",
    "assets/images/Food Photography/8.jpg",
    "assets/images/Food Photography/9.jpg",
    "assets/images/Food Photography/10.jpg",
    "assets/images/Food Photography/11.jpg",
    "assets/images/Food Photography/12.jpg",
    "assets/images/Food Photography/13.jpg",
    "assets/images/Food Photography/14.jpg",
    "assets/images/Food Photography/15.jpg",
    "assets/images/Food Photography/16.jpg",
    "assets/images/Food Photography/17.jpg",
    "assets/images/Food Photography/18.jpg",
  
    
  ];


  const openModal = (image) => {
    setCurrentImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentImage('');
  };



  
  return (
    <div className="App">
      <div className="page-wrapper">
      <Helmet>
      <title>Clove Restaurant - Culinary Excellence in Pollachi</title>
      <meta name="description" content='Welcome to Clove, Pollachis premier dining destination. Experience a blend of tradition and innovation with our diverse menu.' />
      <link rel="canonical" href="https://clovefinedine.com/pollachi" />

    </Helmet>



    <Header/>
 

  {/* Hero Area Start */}
  <section
    className="hero-area bgs-cover pt-180 rpt-150 pb-100 rel z-1"
    style={{ backgroundImage: "url(assets/images/background/hero.jpg)" }}
  >
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
        <div
              className="hero-content text-white"
              data-aos="fade-left"
              data-aos-duration={1500}
              data-aos-offset={50}
            >
              <span className="sub-title mb-35">
                <i className="far fa-hamburger" /> 
                {/* Start price Only ₹199 */}Crafted Flavours
              </span>
              
              <h1>
                {/* Traditional Dishes to Try */}
                CURATED FOR INDIAN PALETTE
                
              </h1>
              <p>
                Hidden Culinary Treasures
              </p>
              <Link to="/menu" className="theme-btn">
                View All Menu <i className="far fa-arrow-alt-right" />
              </Link>
            </div>

        </div>
        <div
          className="col-lg-6"
          data-aos="fade-right"
          data-aos-duration={1500}
          data-aos-offset={50}
        >
          <div className="hero-images rmt-60">
            <img src="assets/images/hero/hero-right.png" alt="Hero" />
            <div className="price">
             
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="hero-shapes">
      <div className="shape one">
        {/* <img src="assets/images/shapes/hero-shape1.png" alt="Hero Shape" /> */}
      </div>
      <div className="shape two">
        {/* <img src="assets/images/shapes/hero-shape2.png" alt="Hero Shape" /> */}
      </div>
      <div className="shape three">
        {/* <img src="assets/images/shapes/hero-shape3.png" alt="Hero Shape" /> */}
      </div>
      <div className="shape four">
        {/* <img src="assets/images/shapes/hero-shape4.png" alt="Hero Shape" /> */}
      </div>
      <div className="shape five">
        {/* <img src="assets/images/shapes/hero-shape5.png" alt="Hero Shape" /> */}
      </div>
    </div>
  </section>
  {/* Hero Area End */}
  {/* Headline area start */}
  <div className="headline-area pt-120 rpt-90 rel z-1">
    <span className="marquee-wrap">
      <span className="marquee-inner left">
        <span className="marquee-item">Lucknowi chicken biryani
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Butter garlic prawns
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Korean fried chicken
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Pineapple tikka
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
      </span>
      <span className="marquee-inner left">
        <span className="marquee-item">Lucknowi chicken biryani
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Butter garlic prawns
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Korean fried chicken
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Pineapple tikka
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
      </span>
      <span className="marquee-inner left">
        <span className="marquee-item">Lucknowi chicken biryani
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Butter garlic prawns
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Korean fried chicken
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Pineapple tikka
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
      </span>
    </span>
    <div className="headline-shapes">
      <div className="shape one">
        {/* <img src="assets/images/shapes/chillies.png" alt="Shape" /> */}
      </div>
      <div className="shape two">
        {/* <img src="assets/images/shapes/tomato.png" alt="Shape" /> */}
      </div>
    </div>
  </div>
  {/* Headline Area end */}
  {/* About Us Area start */}
  <section className="about-us-area pt-130Saqib rpt-85 pb-100 rpb-70 rel z-1">
    <div className="container">
      <div className="row align-items-end">
        <div className="col-lg-6"  style={{padding:'10px'}}>
          <div 
            className="about-image-part mb-30 rmb-55"
            data-aos="fade-right"
            data-aos-duration={1500} style={{ paddingBottom:'66px' }}
          >
            {/* <div className="food-review">
              <div className="author">
                <img src="assets/images/about/review-author.png" alt="Author" />
              </div>
              <span className="text">Very good food</span>
              <div className="ratting">
                <i className="fas fa-star" />
                <i className="fas fa-star" />
              </div>
            </div> */}
            <img src="assets/images/new/About Us.png" alt="About" />
            {/* <div
              className="quality-food"
              style={{
                backgroundImage: "url(assets/images/shapes/about-star.png)"
              }}
            >
              <span className="for-border" />
              <span className="text">
                quality <br />
                food
              </span>
            </div> */}
          </div>
        </div>
        <div className="col-lg-6" style={{padding:'80px'}}>
          <div
            className="about-us-content"
            data-aos="fade-left"
            data-aos-duration={1500}
          >
            <div className="section-title mb-25">
              <span className="sub-title mb-5" style={{ fontSize:'40px'}}>learn About Clove</span>
              {/* <h2>the amazing &amp; Quality food for your good health</h2> */}
            </div>
            <p>

            Welcome to Clove, where we believe that great food brings people together. Established in 2023, our mission is to create memorable dining experiences that satisfy hunger and delight the taste buds of our guests. At Clove, we focus on building a community of loyal customers who appreciate quality, flavor, and the comfort of a well-prepared meal. Come join us and discover a new favorite dish crafted with care and passion.

            </p>
           
            <div className="row">
              <div className="col-sm-6">
                <div className="service-item style-two">
                  <div className="icon">
                    <i className="flaticon-high-quality" />
                  </div>
                  <h5>
                    <p>Best Quality Food</p>
                  </h5>
                  <p>
                  Indulge in the finest quality food crafted with fresh, premium ingredients, delivering unmatched taste and nutrition in every bite.
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="service-item style-two">
                  <div className="icon">
                    <i className="flaticon-chef" />
                  </div>
                  <h5>
                    <p>Experience our Chefs</p>
                  </h5>
                  <p>
                  Experience our chefs' culinary expertise, where passion meets precision to create extraordinary dishes that delight your senses.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="about-shapes">
      <div className="shape one">
        {/* <img src="assets/images/shapes/pizza-three.png" alt="Shape" /> */}
      </div>
    </div>
  </section>
  <section
    className="offer-area bgc-black pt-160 rpt-100 pb-150 rpb-120 rel z-1"
    style={{
      backgroundImage: "url(assets/images/background/offer-dot-bg.png)"
    }}
  >
    <span className="marquee-wrap style-two text-white">
      <span className="marquee-inner left">special deal</span>
      <span className="marquee-inner left">special deal</span>
      <span className="marquee-inner left">special deal</span>
    </span>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <div
            className="offer-content text-white rmb-55"
            data-aos="fade-left"
            data-aos-delay={50}
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <img src="assets/images/offer/delicious.png" alt="Image" />
            <h2>MUTTON RAAN
              {/* Special deal offer Chicken */}

            </h2>
            <h3>
              {/* grilled Chicken only <span>₹199 */}
              PREBOOK YOUR ORDERS NOW

              {/* </span> */}
            </h3>
            <p>
              {/* Restaurant, where culinary excellence meets warm hospitality in
              every dish we serve nestled in the heart of city */}
              Indulge in our succulent mutton raan, slow-cooked to perfection with rich spices and tender, fall-off –the bone meat.

            </p>
            <Link to="/menu" className="theme-btn">
            Order Now 
               {/* <i className="far fa-arrow-alt-right" /> */}
            </Link>
          </div>
        </div>
        <div className="col-lg-6">
          <div
            className="offer-image"
            data-aos="fade-right"
            data-aos-delay={50}
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            {/* <img src="assets/images/offer/offer-img.png" alt="Special deal offer Chicken" /> */}
                        <img src="assets/naan.png" alt="Special deal offer Chicken" />

            {/* <div
              className="offer-badge"
              style={{
                backgroundImage:
                  "url(assets/images/shapes/offer-circle-shape.pngaaa)"
              }}
            >
              <span>
                only <br />
                <span className="price">₹199</span>
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    <div className="offer-shapes">
      <div className="shape one">
        {/* <img src="assets/images/shapes/offer-shape1.png"/> */}
      </div>
      <div className="shape two">
        {/* <img src="assets/images/shapes/offer-shape2.png" /> */}
      </div>
      <div className="shape three">
        {/* <img src="assets/images/shapes/offer-shape3.png" /> */}
      </div>
    </div>
  </section>
  
  {/* Offer Area end */}
  {/* Headline area start */}
  <div className="headline-area pt-120 rpt-90 rel z-1">
    <span className="marquee-wrap">
     
      
    <span className="marquee-inner left">
        <span className="marquee-item">Lucknowi chicken biryani
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Butter garlic prawns
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Korean fried chicken
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Pineapple tikka
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
      </span>
      <span className="marquee-inner left">
        <span className="marquee-item">Lucknowi chicken biryani
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Butter garlic prawns
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Korean fried chicken
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Pineapple tikka
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
      </span>
      <span className="marquee-inner left">
        <span className="marquee-item">Lucknowi chicken biryani
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Butter garlic prawns
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Korean fried chicken
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
        <span className="marquee-item">Pineapple tikka
        </span>
        <span className="marquee-item">
          <i className="flaticon-star" />
        </span>
      </span>

    </span>
    <div className="headline-shapes">
      <div className="shape one">
        {/* <img src="assets/images/shapes/chillies.png" alt="chillies" /> */}
      </div>
      <div className="shape two">
        {/* <img src="assets/images/shapes/tomato.png" alt="tomato" /> */}
      </div>
    </div>
  </div>
  {/* Headline Area end */}
  {/* Popular Menu Area start */}
  <section className="popular-menu-area pt-105 rpt-85 pb-100 rpb-70 rel z-1">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-7 col-lg-8">
          <div
            className="section-title text-center mb-50"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <span className="sub-title mb-5">popular menu</span>
            <h2>
              Explore our popular food
            </h2>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div
          className="col-xl-5 col-lg-6 z-3"
          data-aos="fade-up"
          data-aos-duration={1500}
          data-aos-offset={50}
        >
          <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Honey chilli potato
                </h5>
                <p>Deep fried potatoes coated with tomato chilli sauce and honey</p>
              </div>
             
            </div>
          
          </div>
          
          <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Mushroom cheese khumb</h5>
                <p>Cheese stuffed mushroom baked in tandoor oven</p>
              </div>
             
            </div>
         
          </div>

          <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Mirchi tikka
                </h5>
                <p>Green chilli marinade</p>
              </div>
             
            </div>
            
          </div>
          <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Chicken tikka biryani

                </h5>
                <p>Flavoured fragrant rice with mild spices and chicken tikkas</p>
              </div>
            
            </div>
          
          </div>

          <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Veg burnt garlic fried rice

                </h5>
                <p>Cooked rice sautéed with finely chopped vegetables,
                Stir fried garlic</p>
              </div>
             
            </div>
            
          </div>


          <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Egg sichuan noodles
                </h5>
                <p>Boiled noodles and stir fried vegetables and egg tossed in sichuan sauce</p>
              </div>
             
            </div>
           
          </div>

          <div className="food-item mb-30" style={{ height:'155px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Patiala chicken gravy

                </h5>
                <p>Marinated chicken cooked with various spices and curried</p>
              </div>
             
            </div>
           
          </div>

          <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Butter phulka

                </h5>
                <p>Hot steam cooked flat wheat bread</p>
              </div>
             
            </div>
            
          </div>


        </div>
       
        <div
          className="col-xl-5 col-lg-6 z-1"
          data-aos="fade-up"
          data-aos-delay={100}
          data-aos-duration={1500}
          data-aos-offset={50}
        >
        
        <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Butter garlic naan
                </h5>
                <p>Single layered flat bread with finely chopped garlic and butter</p>
              </div>
              
            </div> 
            
          </div>
        
          
          <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Broccoli malai tikka 
                </h5>
                <p>Broccoli marinade with hung curd, cheese and exortic Indian spices cooked in a coal fired tandoor</p>
              </div>
              
            </div>
            
          </div>


          <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Lucknowi chicken Biryani
                </h5>
                <p>Flavoured fragrant rice with mild spices and chicken</p>
              </div>
             
            </div>
            
          </div>


          <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Butter garlic prawns
                </h5>
                <p>A classic combination of butter, garlic and prawns</p>
              </div>
             
            </div>
            
          </div>

          <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Korean fried chicken
                </h5>
                <p>Deep fried chicken tossed in hot and sweet sauce</p>
              </div>
             
            </div>
           
          </div>

           <div className="food-item mb-30" style={{ height:'150px'}}>
            <div className="content">
              <div className="name-desc">
                <h5>Pineapple tikka
                </h5>
                <p>Pineapple cubes marinated with spices and cooked in skewers</p>
              </div>
             
            </div>
            
          </div>
          <div className="food-item mb-30">
            <div className="content">
              <div className="name-desc">
                <h5>Rabadi
                </h5>
                <p>Traditional North Indian sweet made with full fat milk, cardamom and nuts</p>
              </div>
             
            </div>
           
          </div>

         
        </div>
      </div>
    </div>
  </section>
  
  {/* Testimonials Area start */}
  <section className="testimonials-area bgc-black pt-105 rpt-85 pb-130 rpb-100 rel z-1">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-8">
          <div
            className="section-title text-white text-center mb-50"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <span className="sub-title mb-5">TESTIMONIALS</span>
              <h2>Discover what our guests have to say about
                  their unforgettable dining experiences
              </h2>

          </div>
        </div>
      </div>
      <div className="testimonials-active">
          <div style={{ padding: '40px',fontWeight:'bold'  }}>
              
              <OwlCarousel className="owl-theme" {...optionsTest}>
                <div className="testimonial-container" style={{ padding: '30px', backgroundColor: '#ffffff', boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)', margin: '10px' }}>
                  <div className="quote">
                    <i className="flaticon-quote" style={{ fontSize:'70px',color:'#EC3D08' }} />
                  </div>
                  
                  <p style={{ fontSize: '1.1em', lineHeight: '1.6', color: '#333' ,height:'250px' }}>
                    Exceptional dining experience at Clove Restaurant! The ambience was inviting, the staff were attentive and friendly, and the menu offered a delightful array of flavorful dishes. Each bite was a culinary delight, and the presentation was impeccable. A must visit for anyone looking for a top-notch dining experience!
                  </p>
                  <div className="author" style={{ textAlign: 'right', fontWeight: 'bold', color: '#EC3D08', marginTop: '10px' }}>- Aslam Sheik</div>
                </div>

                <div className="testimonial-container" style={{  padding: '30px', backgroundColor: '#ffffff', boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)', margin: '10px' }}>
                  <div className="quote">
                    <i className="flaticon-quote" style={{ fontSize:'70px',color:'#EC3D08' }} />
                  </div>
                    
                  <p style={{ fontSize: '1.1em', lineHeight: '1.6', color: '#333',height:'250px' }}>
                    Good taste and quantity of food provided.
                  </p>
                  <div className="author" style={{ textAlign: 'right', fontWeight: 'bold', color: '#EC3D08', marginTop: '10px' }}>- Shankara Sharma</div>
                </div>

                <div className="testimonial-container" style={{ padding: '30px', backgroundColor: '#ffffff', boxShadow: '0 5px 10px rgba(0, 0, 0, 0.1)', margin: '10px' }}>
                   <div className="quote">
                       <i className="flaticon-quote" style={{ fontSize:'70px',color:'#EC3D08' }} />
                   </div>
                   <p style={{ fontSize: '1.1em', lineHeight: '1.6', color: '#333',height:'250px'  }}>
                    Every dish we tried at Clove was truly delicious. The snacks available outside the restaurant were also exceptional. Their rolls, chaat items, and momos were all delightful. Additionally, they offer unique dessert options, all of which were outstanding. The Punjabi Kheer and Mango Mousse were excellent.
                  </p>
                  <div className="author" style={{ textAlign: 'right', fontWeight: 'bold', color: '#EC3D08', marginTop: '10px' }}>- Vinoth Kumar</div>
                </div>
              </OwlCarousel>
            </div>
          {/* <div className="testimonial-item">
          <div className="quote">
            <i className="flaticon-quote" />
          </div>
          <div className="text">
            Renowned for its versatility in the kitchen, Red King Crab can be
            prepared in various ways, from simple steaming or boiling to
            elaborate preparations such as grilling incorporating
          </div>
          <div className="author">
            <img src="assets/images/testimonials/author3.jpg" alt="Author" />
            <div className="description">
              <h5>Steven H. Paxson</h5>
              <span>CEO &amp; Founder</span>
            </div>
          </div>
          </div> */}
      </div>
    </div>
    <div className="testimonials-shapes">
      <div className="shape one">
        {/* <img src="assets/images/shapes/hero-shape4.png" alt="Shape" /> */}
      </div>
      <div className="shape two">
        {/* <img src="assets/images/shapes/tomato.png" alt="Shape" /> */}
      </div>
    </div>
  </section>
  {/* Testimonials Area end */}

  {/* Popular Menu Area end */}
  {/* Gallery Area Start */}
  {/* <div className="gallery-area rel z-1">
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-xl-4 col-sm-6">
          <div
            className="gallery-item"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <div className="gallery-image">
              <img src="assets/images/gallery/gallery1.jpg" alt="chicken" />
            </div>
            <div className="gallery-content">
              <h5>Chicken</h5>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-6">
          <div
            className="gallery-item"
            data-aos="fade-up"
            data-aos-delay={50}
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <div className="gallery-image">
              <img src="assets/images/gallery/gallery2.jpg" alt="cake" />
            </div>
            <div className="gallery-content">
              <h5>Cake</h5>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-sm-6">
          <div
            className="gallery-item"
            data-aos="fade-up"
            data-aos-delay={100}
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <div className="gallery-image">
              <img src="assets/images/gallery/gallery3.jpg" alt="ice cream" />
            </div>
            <div className="gallery-content">
              <h5>Ice Cream</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* Gallery Area End */}
  {/* Why choose Us Area start */}
  <section className="why-choose-area bgc-lighter pt-240 rpt-150 pb-100 rpb-70 rel z-1">
    <span className="marquee-wrap style-two">
      <span className="marquee-inner left">Why choose Us</span>
      <span className="marquee-inner left">Why choose Us</span>
      <span className="marquee-inner left">Why choose Us</span>
    </span>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <div
            className="why-choose-content rmb-30"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <div className="section-title mb-25">
              <span className="sub-title mb-5">Why choose us</span>
              <h2>
                We&nbsp;Offer&nbsp;quality service
                That&nbsp;Customers&nbsp;Needs
              </h2>
            </div>
            <p>
            At Clove Restaurant, our vision is to create a unique culinary experience that celebrates the richness of flavors and
the joy of dining. We strive to be a place where every guest feels welcomed, valued, and delighted with each bite.
Our commitment is to craft memorable meals that not only satisfy hunger but also nourish the soul, fostering a
loyal community of diners who return for the warmth, quality, and authenticity of our offerings. Through
innovation, passion, and dedication to excellence, we aim to become a cherished dining destination for those
seeking a truly unforgettable experience.
            </p>
           
            <div className="row">
              {/* <div className="col-sm-4 col-6">
                <div className="counter-item counter-text-wrap">
                  <span
                    className="count-text k-plus"
                    data-speed={3000}
                    data-stop={10}
                  >
                    0
                  </span>
                  <span className="counter-title">Organic Planting</span>
                </div>
              </div> */}
              <div className="col-sm-4 col-6">
                <div className="counter-item counter-text-wrap">
                  <span
                    className="count-text plus"
                    data-speed={3000}
                    data-stop={5}
                  >
                    0
                  </span>
                  <span className="counter-title">Passionate Chef’s</span>
                </div>
              </div>
              <div className="col-sm-4 col-6">
                <div className="counter-item counter-text-wrap">
                  <span
                    className="count-text plus"
                    data-speed={3000}
                    data-stop={20}
                  >
                    0
                  </span>
                  <span className="counter-title">Favourite Dishes</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="row">
            <div className='col-12'>
                   <img src="assets/images/shapes/Why Choose.png" alt="pizaa" />
            </div>
            {/* <div
              className="col-sm-6"
              data-aos="fade-up"
              data-aos-delay={50}
              data-aos-duration={1500}
              data-aos-offset={50}
            >
              <div className="service-item">
                <div className="icon">
                  <i className="flaticon-recommended-food" />
                </div>
                <h4>
                  <a >Best Quality Food</a>
                </h4>
                <p>Experience gourmet cuisine crafted with the finest ingredients.</p>
              </div>
              <div className="service-item">
                <div className="icon">
                  <i className="flaticon-fast-delivery" />
                </div>
                <h4>
                  <a>fast food delivery</a>
                </h4>
                <p>Your satisfaction is our priority—get a full refund if you're not completely satisfied.</p>
              </div>
            </div>
            <div
              className="col-sm-6"
              data-aos="fade-up"
              data-aos-delay={100}
              data-aos-duration={1500}
              data-aos-offset={50}
            >
              <div className="service-item mt-30 rmt-0">
                <div className="icon">
                  <i className="flaticon-cashback" />
                </div>
                <h4>
                  <a >money back guarantee</a>
                </h4>
                <p>Enjoy your favorite meals delivered hot and fresh right to your doorstep.</p>
              </div>
              <div className="service-item">
                <div className="icon">
                  <i className="flaticon-dish" />
                </div>
                <h4>
                  <a >100% natural food</a>
                </h4>
                <p>Indulge in pure, wholesome ingredients with no artificial additives.</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    <div className="headline-shapes">
      <div className="shape one">
      {/* <img src="assets/images/shapes/pizza-three.png" alt="pizaa" /> */}

      </div>
      <div className="shape two">
        {/* <img src="assets/images/shapes/tomato.png" alt="tomato" /> */}
      </div>
      <div className="shape three">
        {/* <img src="assets/images/shapes/chillies.png" alt="chillies" /> */}

      </div>
    </div>
  </section>
  
  <section className="cta-area py-100 rel z-1">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 col-md-8">
          <div className="cta-content">
            <div className="section-title text-white mb-20">
              <span className="sub-title mb-5">Reserve Your Table</span>
              <h2>Join us for a meal to remember</h2>
              <p>Book your table at Clove today and indulge in a dining experience like no other</p>
            </div>
            <Link to="/reservation" className="theme-btn style-two">
             Reserve Now <i className="far fa-arrow-alt-right" />
            </Link>
            {/* <div
              className="cta-badge"
              style={{
                backgroundImage: "url(assets/images/shapes/cta-shape.png)"
              }}
            >
              <span>
                Book
                <br /> Now
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    <div
      className="cta-bg"
      // style={{ backgroundImage: "url(assets/images/background/cta.jpg)" }}
     >
      <img src="assets/images/background/cta.jpg" alt="" style={{width:'99%' ,height:'500px' }} />
    </div>
  </section>
  {/* Call To Action Area end */}
  {/* Blog Area start */}
  <section className="blog-area pt-100 rpt-70 pb-90 rpb-60 rel z-1">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div
            className="section-title text-center mb-50"
            
          >
            <span className="sub-title mb-5">clove kiosk</span>
            <h2>Discover the joy of tasty Desi Chaat</h2>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">

      {blogs.length > 0 ? (
                blogs.map(blog => (
        <div className="col-xl-4 col-md-6" key={blog.id}>
          <div
            className="blog-item"
         
          >
            {/* to={`/${blog.slug}`}/ */}
          <Link >             
            <div className="image">
              <img src={`https://admin.clovefinedine.com/public/${blog.img}`} alt={blog.alt_tag}  />
              {/* <ul className="blog-meta text-white">
                <li>
                  <a >Quality Food</a>
                </li>
                <li>
                   <a><AutoDate /></a>
                </li>
                <li>
                  <a>Explore</a>
                </li>
                
              </ul> */}
            </div>
            <div className="content" >
              <h4>
                <a>
                {blog.title}
                </a>
              </h4>
              <p style={{ height:'100px' }}>
              {blog.description_short}
              </p>
            
              <hr style={{color:'#EC3D08',height:'3px',width:'50px',opacity:'1'}}></hr>

            </div>
            </Link>
          </div>
        </div>
          ))
        ) : (
            <p>Loading...</p>
        )}

      </div>
      <div className='row'>
        <div className='col-12'>
       <p>Welcome to our vibrant chaat kiosk, where we bring the authentic flavors of North India straight to your plate. Specializing in a variety of mouthwatering chaats, we offer a delicious mix of tangy, spicy, and sweet snacks that are perfect for your evenings. Each dish is prepared with fresh ingredients and traditional spices, capturing the true essence of street food. Whether you're craving something crunchy, savory, or refreshing, our chaat kiosk has something to satisfy every palate.
       </p> 
        </div>
      </div>
    </div>


  </section>
      
 

      
      <div style={{ padding:'40px',marginTop:'px' }}>
      <OwlCarousel className="owl-theme" {...options}>
        {images.map((image, index) => (
          <div key={index} className="img-container" onClick={() => openModal(image)}>
            <img src={image} alt={`Slide ${index + 1}`} />
            <div className="overlay"></div>
          </div>
        ))}
      </OwlCarousel>

      {modalOpen && (
        <div className="modal">
          <span className="close" onClick={closeModal}>&times;</span>
          <img className="modal-content" style={{width:'700px',padding:'0px', background:'none',top:'60px',margin:'0px auto'}} src={currentImage} alt="Enlarged view" />
        </div>
      )}
    </div>



   <Footer/>
   
</div>

    </div>
  );
}

export default Home;
