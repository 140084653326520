import Header from './components/Header';
import Footer from './components/Footer';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function History() {

  const loadScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    script.onerror = () => console.error(`Failed to load script: ${src}`);
    document.head.appendChild(script);
  };

  useEffect(() => {
    // List of scripts to load
    const scripts = [
      
      '/assets/js/scriptaaa.js'
    ];

    // Load each script
    scripts.forEach((src, index) => {
      loadScript(src, () => {
        console.log(`Script loaded: ${src}`);
        // Optionally handle initialization here
      });
    });
    window.scrollTo(0, 0);

    // Cleanup function (if needed)
    return () => {
      // Optionally clean up if necessary
    };
  }, []);
  return (
    <div className="page-wrapper">
    <Helmet>
      <title>The Story of Clove Restaurant - Tradition Meets Innovation</title>
      <meta name="description" content='Learn about the rich history of Clove in Pollachi. Our journey blends culinary tradition with innovative techniques' />
      <link rel="canonical" href="https://clovefinedine.com/history" />
    </Helmet>
    
    <Header/>
   
    <section
      className="page-banner-area overlay pt-215 rpt-150 pb-160 rpb-120 rel z-1 bgs-cover text-center"
      style={{ backgroundImage: "url(assets/images/background/History.jpg)" }}
    >
      <div className="container">
        <div className="banner-inner text-white">
          <h1
            className="page-title"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            About Us
          </h1>
          <nav aria-label="breadcrumb">
            <ol
              className="breadcrumb justify-content-center"
              data-aos="fade-up"
              data-aos-delay={200}
              data-aos-duration={1500}
              data-aos-offset={50}
            >
              <li className="breadcrumb-item">
                <a>Home</a>
              </li>
              <li className="breadcrumb-item active">About Us</li>
            </ol>
          </nav>
        </div>
      </div>
    </section>
    {/* Page Banner End */}
    {/* History Page About Area start */}
    <section className="history-page-about pt-130 rpt-100 pb-75 rpb-45 rel z-1">
      <div className="container">
        <div className="row gap-100 align-items-center">
          <div className="col-xl-6">
            <div
              className="history-about-image mb-55"
              data-aos="fade-left"
              data-aos-duration={1500}
              data-aos-offset={50}
            >
              <img src="assets/images/history/logo192.png" alt="History clove resturant" />
            </div>
          </div>
          <div className="col-xl-6">
            <div
              className="history-about-content mb-55"
              data-aos="fade-right"
              data-aos-duration={1500}
              data-aos-offset={50}
            >
              <div className="section-title mb-25">
                <span className="sub-title mb-5">About Us</span>
              </div>
              <p>
              At Clove, We have successfully catered events for up to 50 guests, providing an unforgettable experience with our thoughtfully crafted dishes. Our team is well-equipped to cater events for up to 100 guests, ensuring that each meal is prepared with the same dedication to quality and flavor that defines Clove. Whether you're hosting a small gathering or a larger event, Clove is ready to make your occasion special and memorable.
              </p>
              <div className="row justify-content-between">
                <div className="col-md-7">
                  <div className="history-progress one">
                    <span className="counting">0</span>
                    <h3>Professional Chef</h3>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="history-progress two">
                    <span className="counting">0</span>
                    <h3>Satisfied Customers</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
   
    <Footer/>
  </div>
  
  
  
  );
}

export default History;
