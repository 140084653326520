import Header from './components/Header';
import Footer from './components/Footer';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

function Gallery() {

  const loadScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    script.onerror = () => console.error(`Failed to load script: ${src}`);
    document.head.appendChild(script);
  };

  useEffect(() => {
    // List of scripts to load
    const scripts = [
      
      '/assets/js/scriptaaa.js'
    ];

    // Load each script
    scripts.forEach((src, index) => {
      loadScript(src, () => {
        console.log(`Script loaded: ${src}`);
        // Optionally handle initialization here
      });
    });
    window.scrollTo(0, 0);

    // Cleanup function (if needed)
    return () => {
      // Optionally clean up if necessary
    };
  }, []);


  const images = [
    // "assets/images/gallerys/Image 1.jpg",
    // "assets/images/gallerys/Image 2.jpg",
    // "assets/images/gallerys/Image 3.jpg",
    // "assets/images/gallerys/Image 4.jpg",
    // "assets/images/gallerys/Image 5.jpg",
    // "assets/images/gallerys/Image 6.jpg",
    // "assets/images/gallerys/Image 7.jpg",
    // "assets/images/gallerys/Image 8.jpg",
    // "assets/images/gallerys/Image 9.jpg",
    // "assets/images/gallerys/Image 10.jpg",
    // "assets/images/gallerys/Image 11.jpg",
    // "assets/images/gallerys/Image 12.jpg",
    "assets/images/Food Photography/1.jpg",
    "assets/images/Food Photography/2.jpg",
    "assets/images/Food Photography/3.jpg",
    "assets/images/Food Photography/4.jpg",
    "assets/images/Food Photography/5.jpg",
    "assets/images/Food Photography/6.jpg",
    "assets/images/Food Photography/7.jpg",
    "assets/images/Food Photography/8.jpg",
    "assets/images/Food Photography/9.jpg",
    "assets/images/Food Photography/10.jpg",
    "assets/images/Food Photography/11.jpg",
    "assets/images/Food Photography/12.jpg",
    "assets/images/Food Photography/13.jpg",
    "assets/images/Food Photography/14.jpg",
    "assets/images/Food Photography/15.jpg",
    "assets/images/Food Photography/16.jpg",
    "assets/images/Food Photography/17.jpg",
    "assets/images/Food Photography/18.jpg",
  
    
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
<div className="page-wrapper">
   <Header/>
   <Helmet>
      <title>Clove Restaurant Gallery - A Visual Feast</title>
      <meta name="description" content='Explore the ambiance and culinary delights of Clove through our gallery. Get a glimpse of the elegant dining experience that awaits you in Pollachi.' />
      <link rel="canonical" href="https://clovefinedine.com/gallery" />

    </Helmet>
  {/*Form Back Drop*/}
  <div className="form-back-drop" />
  
  <section
    className="page-banner-area overlay pt-215 rpt-150 pb-160 rpb-120 rel z-1 bgs-cover text-center"
    style={{ backgroundImage: "url(assets/images/background/banner.jpg)" }}
  >
    <div className="container">
      <div className="banner-inner text-white">
        <h1
          className="page-title"
          data-aos="fade-up"
          data-aos-duration={1500}
          data-aos-offset={50}
        >
          Gallery
        </h1>
        <nav aria-label="breadcrumb">
          <ol
            className="breadcrumb justify-content-center"
            data-aos="fade-up"
            data-aos-delay={200}
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <li className="breadcrumb-item">
              <a>Home</a>
            </li>
            <li className="breadcrumb-item active">Gallery</li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  {/* Page Banner End */}
  {/* Gallery Area start */}
  <section className="photo-gallery-area pt-130 rpt-100 pb-60 rpb-30 rel z-1">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-12 col-lg-12 col-12">
          <div
            className="section-title text-center mb-50"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <span className="sub-title mb-5">photo gallery</span>
            <h2 style={{fontSize:'55px'}}>explore latest photo gallery</h2>
          </div>
        </div>

        {images.map((image, index) => (
        <div className="col-lg-4 col-sm-6 item potatoes" key={index}>
          <div
            className="gallery-item-three"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <img
              src={image}
              onClick={() => setSelectedImage(image)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      ))}

      {selectedImage && (
        <div className="lightbox" onClick={handleClose}>
          <span className="close">&times;</span>
          <img className="lightbox-content" src={selectedImage} />
        </div>
      )}

      <style jsx>{`
        .lightbox {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          z-index: 99999;
        }
        .lightbox-content {
          max-width: 80%;
          max-height: 80%;
        }
        .close {
          position: absolute;
          top: 20px;
          right: 20px;
          font-size: 40px;
          color: #fff;
          cursor: pointer;
        }
      `}</style>


      </div>
     
      
    </div>
  </section>
  <Footer/>
</div>

  
  );
}

export default Gallery;
