import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './menu';
import Index from './home';
import About from './about';
import History from './history';
import Gallery from './Gallery';
import Contact from './contact';
import Clove from './clove';
import Splash from './splash';
import Coimbatore from './coimbatore';
import Details from './details';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div>
          <Routes>
            <Route path="/" element={<Splash />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/reservation" element={<About />} />
            <Route path="/about-us" element={<History />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pollachi" element={<Index />} />
            <Route path="/coimbatore" element={<Coimbatore />} />
            <Route path="/:slug" element={<Details />} />
          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
